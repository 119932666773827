// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-recommended-index-js": () => import("./../../../src/pages/recommended/index.js" /* webpackChunkName: "component---src-pages-recommended-index-js" */),
  "component---src-pages-topics-index-js": () => import("./../../../src/pages/topics/index.js" /* webpackChunkName: "component---src-pages-topics-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-privacy-policy-page-js": () => import("./../../../src/templates/privacy-policy-page.js" /* webpackChunkName: "component---src-templates-privacy-policy-page-js" */),
  "component---src-templates-services-page-js": () => import("./../../../src/templates/services-page.js" /* webpackChunkName: "component---src-templates-services-page-js" */),
  "component---src-templates-stories-page-js": () => import("./../../../src/templates/stories-page.js" /* webpackChunkName: "component---src-templates-stories-page-js" */),
  "component---src-templates-topics-js": () => import("./../../../src/templates/topics.js" /* webpackChunkName: "component---src-templates-topics-js" */),
  "component---src-templates-videos-page-js": () => import("./../../../src/templates/videos-page.js" /* webpackChunkName: "component---src-templates-videos-page-js" */)
}

